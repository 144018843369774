<template>
  <div>
    <CRow>
      <CCol sm="12">
        <ManagersTable
          :items="managers"
          hover
          striped
          border
          small
          fixed
          caption="Supervisores"
          @refresh="refreshItems"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import ManagersTable from '../../components/managers/ManagersTable.vue'
import ws from '../../services/managers';
import store from '../../store'

export default {
  name: 'Managers',
  components: { ManagersTable },
  data: function () {
		return {
            managers: []
        }
  },
  mounted: async function() {
    this.loading();
    let response = await ws.get();

    if(response.type == "success"){
      this.managers = response.data;
    }

    this.loaded();
  },
  methods: {
    async refreshItems (items){
      this.loading();
      this.managers = await items;
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
